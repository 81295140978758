import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './core/App';
import { QueryClient, QueryClientProvider } from 'react-query';


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

ReactDOM.render(
    <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true, }}>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
