import pt_br from "../../core/lang/pt-br";
import { Lang } from "../../core/services/Lang/types";

const ptBR: Lang = {
    ...pt_br,
    modules: {
        visaogeral: {
            name: "Visão Geral"
        },
        dashboard: {
            name: "Visão Geral"
        },

        fiscal: {
            name: "Fiscal",
            nfse: {
                name: "NFS-e - Notas de Serviço",
                singular: "NFS-e",
                feminine: true,
            }
        },

        // ############################################################################################################## SALES

        comercial: {
            name: "Comercial",
            clientes: {
                name: 'Clientes',
                singular: 'Cliente',
                tabs: ['Dados', 'Adicional', 'Observações', 'Débitos', 'Lançamentos', 'Vendas', 'Contratos'],
                texts: {
                    Tipo: 'Esse cliente também é um fornecedor'
                },
                options: {
                    SomenteClientesVendedor: `Mostrar somente clientes do vendedor`
                },
                fields: {
                    InformacoesAdicionais: 'Informações Adicionais',
                    Complemento: 'Complemento',
                }
            },

            clientestabelaspreco: {
                name: 'Tabelas de Preços',
                singular: 'Tabela de Preços',
                feminine: true,
                fields: {
                    TabelaId: 'Tabela de Preços',
                    Nome: 'Tabela de Preços',
                }
            },

            clienteslancamentos: {
                name: 'Lançamentos',
                singular: 'Lançamento',
                fields: {
                    Data: 'Data'
                },
            },

            gruposclientes: {
                name: 'Grupos de Clientes',
                singular: 'Grupo de Clientes'
            },

            tabelaspreco: {
                name: 'Tabelas de Preços',
                singular: 'Tabela de Preços',
                fields: {
                    Tipo: {
                        options: {
                            1: 'Percentual',
                            2: 'Valor',
                        },
                    }
                }
            },

            vendas: {
                name: 'Vendas',
                singular: 'Venda',
                feminine: true,
                tabs: ['Dados', 'Itens', 'Devoluções', 'Entrega'],
                texts: {
                    Totais: 'Totais',
                    modoCatalogo: 'Modo Catálogo',
                    Preco: 'Preço',
                    EstoqueAtual: 'Estoque',
                    PrevisaoEntrada: 'Chegando',
                    PrevisaoSaida: 'Reservado',
                    SaldoEstoque: 'Saldo',
                    RecebimentoIncompleto: 'Você precisa adicionar no mínimo o valor do pedido menos os créditos para esse recebimento.',
                    RecebimentoCrediario: 'Valor a receber não pode ser maior que o valor da venda.',
                    impressaoDevolucoes: 'Devoluções',
                    impressaoDevolucoesCusto: 'Devoluções (Custo)',
                    impressaoEspeciais: 'Produtos Especiais',
                    impressaoResumo: 'Resumo dos Produtos',
                    Assinatura: 'Assinatura',
                },
                fields: {
                    Operacao: 'Situação',
                },
                errors: {
                    1: 'Valor do crediário inválido. Você deve preencher pelo menos com o valor da venda.'
                }
            },

            recebimentos_itens: {
                name: ' ',
                singular: ' ',
            },

            pedidos: {
                name: 'Pedidos/Condicionais',
                singular: 'Pedido/Condicional',
                tabs: ['Dados', 'Itens'],
                options: {
                    PodeConfirmarPedido: 'Pode confirmar pedido/condicional'
                },
                texts: {
                    Totais: 'Totais',
                    modoCatalogo: 'Modo Catálogo',
                    Preco: 'Preço',
                    EstoqueAtual: 'Estoque',
                    PrevisaoEntrada: 'Chegando',
                    PrevisaoSaida: 'Reservado',
                    SaldoEstoque: 'Saldo',
                }
            },

            pedidos_itens: {
                name: 'Itens',
                singular: 'Item',
            },

            comissoes: {
                name: 'Comissões',
                singular: 'Comissão',
            },

        },

        // ############################################################################################################## LOGISTICS

        logistica: {
            name: 'Logística',

            rotasvenda: {
                name: 'Rotas de Venda',
                singular: 'Rota de Venda',
                feminine: true,
                options: {
                    SomenteRotasVendedor: 'Somente Rotas do Vendedor'
                },
            },

            pacotes: {
                name: 'Embalagens',
                singular: 'Embalagem',
                feminine: true,
            },

        },

        //
        producao: {
            name: 'Produção',

            producoes: {
                name: 'Produções',
                singular: 'Produção',
                feminine: true,
                fields: {
                    Operacao: {
                        name: 'Operação',
                        options: {
                            'O': 'Ordem de Produção',
                            'P': 'Produção',
                            'R': 'Produção Parcial',
                        },
                    },
                },
            },

            grupos: {
                name: 'Grupos de Produção',
                singular: 'Grupo de Produção',
            },
        },


        // ############################################################################################################## STOCK


        estoque: {
            name: 'Estoque',

            compras: {
                name: 'Compras',
                singular: 'Compra',
                feminine: true,
                tabs: ['Dados', 'Itens'],
                texts: {
                    Totais: 'Totais',
                    modoCatalogo: 'Modo Catálogo',
                    Preco: 'Preço',
                    EstoqueAtual: 'Estoque',
                    PrevisaoEntrada: 'Chegando',
                    PrevisaoSaida: 'Reservado',
                    SaldoEstoque: 'Saldo',
                },
                fields: {
                    Operacao: {
                        name: 'Operação',
                        options: {
                            'E': 'Compra',
                            'C': 'Pedido',
                            'W': 'Proposta',
                            'x': 'Pedido Confirmado'
                        },
                    },
                },
            },

            compras_itens: {
                name: 'Itens',
                singular: 'Item',
            },

            inventarios: {
                name: 'Inventários',
                singular: 'Inventário',
                fields: {
                    Operacao: {
                        options: {
                            'I': 'Inventário',
                            'N': 'Contagem de Estoque'
                        },
                    },
                },
            },

            transferencias: {
                name: 'Transferências',
                singular: 'Transferência',
                feminine: true,
            },

            perdas: {
                name: 'Perdas/Consumo',
                singular: 'Perda/Consumo',
                feminine: true,
                fields: {
                    Tipo: {
                        options: {
                            'L': 'Perda',
                            'U': 'Consumo'
                        },
                    },
                },
            },

            produtos: {
                name: 'Produtos',
                singular: 'Produto',
                tabs: ['Dados', 'Adicional', 'Produção', 'Tabela de Preços', 'Estoque', 'Imagens'],
                reports: {
                    SugestaoCompra: {
                        Tipo: {
                            options: {
                                0: 'Sugestão Calculada',
                                1: 'Estoque Mínimo',
                                2: 'Ambos',
                                3: 'Todos os Produtos'
                            }
                        }
                    }
                }
            },

            produtos_composicao: {
                name: 'Composição',
                Singular: 'Matéria Prima',
            },


            produtos_categorias: {
                name: 'Categorias',
                singular: 'Categoria',
                feminine: true,
            },

            grupos_categorias: {
                name: 'Categorias',
                singular: 'Categoria',
                feminine: true,
            },


            produtos_compras: {
                name: 'Últimas Compras',
            },

            produtos_lotes: {
                name: 'Lotes',
                Singular: 'Lote',
            },

            produtos_estoques: {
                name: 'Estoques',
                singular: 'Estoque',
            },

            fornecedores: {
                name: 'Fornecedores',
                singular: 'Fornecedor',
                texts: {
                    Tipo: 'Esse fornecedor também é um cliente'
                },
            },


            grupos: {
                name: 'Grupos',
                singular: 'Grupo',
            },

            gruposprodutos: {
                name: 'Grupos',
                singular: 'Grupo',
            },

            categorias: {
                name: 'Categorias',
                singular: 'Categoria',
                feminine: true,
            },

            estoques: {
                name: 'Estoques',
                singular: 'Estoque',
                fields: {
                    Operacao: {
                        optionsTipo: ['S', 'E'],
                        options: {
                            'S': 'Saída',
                            'P': 'Pedido de Venda',
                            'p': 'Pedido de Venda Efetivado',
                            'E': 'Entrada',
                            'C': 'Pedido de Compra',
                            'I': 'Inventário',
                            'N': 'Contagem de Estoque',
                            'D': 'Devolução',
                            'T': 'Transferência',
                        },
                    },
                    Tipo: {
                        options: {
                            'C': 'Compra',
                            'N': 'Inventário',
                            'V': 'Venda',
                            'P': 'Pedido',
                            'D': 'Devolução',
                            'T': 'Transferência',
                            'L': 'Perda',
                            'U': 'Uso/Consumo',
                            'R': 'Produção',
                            'S': 'Serviço',
                        }
                    },
                }
            },

            alteracoes_compras: {
                fields: {
                    Operacao: {
                        options: {
                            'E': 'Compra',
                            'C': 'Pedido',
                            'W': 'Proposta',
                            'x': 'Pedido Confirmado'
                        },
                    },
                }
            },

            alteracoes_inventarios: {
                fields: {
                    Operacao: {
                        options: {
                            'I': 'Inventário',
                            'N': 'Contagem de Estoque',
                        },
                    },
                }
            },
            alteracoes_transferencias: {
                fields: {
                    Tipo: {
                        options: {
                            'T': 'Transfer',
                        },
                    },
                }
            },

            alteracoes_perdas: {
                fields: {
                    Tipo: {
                        options: {
                            'L': 'Perda',
                            'U': 'Consumo'
                        },
                    },
                }
            },
        },

        servicos: {
            name: "Serviços",

            ordensservico: {
                name: "Ordens de Serviço",
                singular: "Ordem de Serviço",
                feminine: true,
            },

            servicos: {
                name: "Serviços",
                singular: "Serviço"
            },

            servicos_tributos: {
                name: "Configuração Tributos",
                singular: "Configuração"
            },

            clientes: { name: 'Clientes' },
            gruposclientes: { name: 'Grupos de Clientes' },
            comissoes: { name: 'Comissões' },
        },

        helpdesk: {
            name: 'Help Desk',

            chamados: {
                name: 'Chamados',
                singular: 'Chamado',
            },

            clientes: {
                name: 'Clientes',
                singular: 'Cliente',
            },

            gruposclientes: {
                name: 'Grupos de Clientes',
                singular: 'Grupo de Clientes',
            },

            produtos: {
                name: 'Produtos',
                singular: 'Produto',
            },

            grupos: {
                name: 'Grupos de Produtos',
                singular: 'Grupo de Produtos',
            },

            categorias: {
                name: 'Categorias de Produtos',
                singular: 'Categoria de Produtos',
                feminine: true,
            },

            tiposchamados: {
                name: 'Tipos de Chamados',
                singular: 'Tipo de Chamado',
            },

            situacoes: {
                name: 'Situações',
                singular: 'Situação',
                feminine: true,
            },
        },
        projetos: {
            name: 'Projetos',

            versoes: {
                name: 'Versões',
                singular: 'Versão',
                feminine: true,
                fields: {

                },
                texts: {

                }
            },

            chamados: {
                name: 'Desenvolvimentos/Correções'
            },

            produtos: {
                name: 'Produtos',
                singular: 'Produto',
            },

            grupos: {
                name: 'Grupos de Produtos',
                singular: 'Grupo de Produtos',
            },

            categorias: {
                name: 'Categorias de Produtos',
                singular: 'Categoria de Produtos',
                feminine: true,
            },
        },
        contratos: {
            name: 'Contratos',

            contratos: {
                name: 'Contratos',
                singular: 'Contrato',

            },

            clientes: {
                name: 'Clientes',
                singular: 'Cliente',
            },

            produtos: {
                name: 'Produtos',
                singular: 'Produto',
            },

            grupos: {
                name: 'Grupos de Produtos',
                singular: 'Grupo de Produtos',
            },

            categorias: {
                name: 'Categorias de Produtos',
                singular: 'Categoria de Produtos',
                feminine: true,
            },

            tiposcontratos: {
                name: 'Tipos de Contratos',
                singular: 'Tipo de Contrato',
            },
        },

        financeiro: {
            name: 'Financeiro',

            lancamentos: {
                name: 'Lançamentos',
                singular: 'Lançamento',

                texts: {
                    SaldoAnterior: 'Saldo Anterior:',
                    Saldo: 'Saldo:',
                    ClassificacaoLancamento: 'Classificação do Lancamento',
                    Pago: 'Pago',
                    Agendado: 'Agendar Pagamento'
                },
                options: {
                    PodeQuitarConta: 'Pode quitar conta'
                },
            },

            cartoes: {
                name: 'Cartões de Crédito',
                singular: 'Lançamento'
            },

            pessoas: {
                name: 'Pessoas',
                singular: 'Pessoa',
                feminine: true,
            },

            metodospagamento: {
                name: 'Métodos de Pagamento',
                singular: 'Métodos de Pagamento',
                feminine: true,
            },

            contas: {
                name: 'Contas',
                singular: 'Conta',
                feminine: true,
            },

            categorias: {
                name: 'Categorias',
                singular: 'Categoria',
                feminine: true,
            },

            convenios: {
                name: "Convênios",
                singular: "Convênio",
            },

            cobrancas: {
                name: 'Cobranças',
                singular: 'Cobrança',
                feminine: true,
            }

        },
        configuracoes: {
            name: 'Configurações',

            etiquetas: {
                name: 'Etiquetas',
                singular: 'Etiqueta',
                feminine: true,
                variables:
                {
                    produto: {
                        Id: 'Código',
                        CodigoBarras: 'Cód. Barras Fabricante',
                        Descricao: 'Descrição',
                        SKU: 'SKU',
                        Fabricante: 'Fabricante',
                        Grupo: 'Grupo',
                        Unidade: 'Unidade',
                        QuantidadePacote: 'Quantidade Pacote',
                        UnidadeItem: 'Unidade Item',
                        PesoBruto: 'Peso Bruto',
                        PesoLiquido: 'Peso Liquido',
                        PrecoVista: 'Preço',
                        PrecoPrazo: 'Preço à Prazo',
                        PrecoPrazo_$: 'Valor Parcela',
                        PrecoSugerido: 'Preço Sugerido',
                        Lote: 'Lote *',
                        Fabricacao: 'Data Fabricação *',
                        Validade: 'Vencimento *',
                    }
                },
                formats: {
                    produto: {
                        PesoBruto: {
                            kind: 'float',
                            format: ',0.000'
                        },
                        PesoLiquido: {
                            kind: 'float',
                            format: ',0.000'
                        },
                        PrecoVista: {
                            kind: 'float',
                            format: ',0.00'
                        },
                        PrecoPrazo: {
                            kind: 'float',
                            format: ',0.00'
                        },
                        PrecoPrazo_$: {
                            kind: 'float',
                            format: ',0.00'
                        },
                        PrecoSugerido: {
                            kind: 'float',
                            format: ',0.00'
                        },
                        Fabricacao: {
                            kind: 'date'
                        },
                        Validade: {
                            kind: 'date'
                        },
                    }
                },
            },


            funcionarios: {
                name: 'Funcionários',
                singular: 'Funcionário',
                texts: {
                    Tipo: 'Esse funcionário é um vendedor'
                },
            },

            funcionarios_comissoes: {
                name: 'Comissões por Categoria',
                singular: 'Comissão',
            },

            usuarios: {
                name: 'Usuários',
                singular: 'Usuário',
            },

            direitos: {
                options: {
                    PodeConfirmarPedido: 'Pode confirmar pedidos',
                    PodeEfetivarAbaixoLucroMinimo: 'Pode efetivar pedidos abaixo do lucro mínimo',
                    PodeMudarPrecoVenda: 'Pode alterar preço de venda',
                    BloquearProdutosAbaixoPrecoMinimo: 'Bloquear produtos abaixo do preço mínimo',
                    PodeMudarPreco: 'Pode alterar preço',
                    SomentePedidosVendedor: 'Mostrar somente pedidos do vendedor',
                    ReprocessarRecebimentos: 'Pode reprocessar recebimentos',
                    PodeQuitarConta: 'Pode quitar contas',
                    SomenteClientesVendedor: `Mostrar somente clientes do vendedor`,
                    SomenteVendasParaEntrega: 'Mostrar somente vendas para entrega',
                    PodeCancelarEntrega: 'Pode cancelar entregas',
                    LucroVenda: 'Mostrar Lucro',
                    vendas: 'Vendas',
                    financeiro: 'Financeiro',
                    helpdesk: 'Help Desk',
                    PodeMudarPrioridade: 'Pode mudar prioridade',
                    PodeTrocarSituacao: 'Pode trocar situação',
                    PodeAlterarDadosContrato: 'Pode mudar dados',
                    PodeModificarContratosAtivos: 'Pode modificar contratos ativos',
                    PodeRemoverAssinatura: 'Pode remover assinaturas',
                    MetodoPagtoObrigatorio: 'Método pagto. obrigatório',
                    BloquearMetodoPagtoVenda: 'Bloquear Método Pagto.',
                    BloquearMetodoPagtoCliente: 'Bloquear Método Pagto.',
                    EsconderPrecoCusto: 'Esconder preço custo',
                    BloquearPrecoVenda: 'Bloquear preços de venda',
                    PodeSelecionarEstoque: 'Pode selecionar estoque',
                    PodeSelecionarLote: 'Pode selecionar lote',
                    GerenciarTabelasPreco: 'Gerenciar tabelas preço',
                    PodeModificarCompletas: 'Pode modificar Confirmadas/Finalizadas',
                    MostrarProdutosSemEstoque: 'Mostrar produtos sem estoque',
                    PermitirImprimir: 'Permitir imprimir listas',
                    PermitirImpCurvaABC: 'Permitir imprimir Curva ABC',
                    RotaEntrega: 'Permite modificar rota de entrega',
                    PermitirEnviarMsgWhatsApp: 'Permitir enviar mensagens por WhatsApp',
                    PermitirImpTabela: 'Permitir imprimir tabelas de preço',
                    BloquearDataVenda: 'Bloquear modificar data/hora',
                    PodeConfirmarPedidoMinimo: 'Pode confirmar pedidos abaixo do mínimo',
                    BloquearClonar: 'Bloquear Clonagem',
                }
            },

            gruposusuarios: {
                name: 'Grupos de Usuários',
                singular: 'Grupo de Usuários',
            },

            parametros: {
                name: 'Parâmetros do Sistema',
                texts: {
                    QuickbooksSync: 'Última Sincronização:',
                    QuickbooksSyncWaiting: 'Aguardando atualização.',
                }
            },

            servicos_tributos: {
                name: 'Tributos dos Serviços',
                singular: 'Tributo dos Serviços'
            },

            apikeys: {
                name: 'Chaves API',
                singular: 'Chave API',
            },

            motivosperdas: {
                name: 'Motivos de Perda/Uso Consumo',
                singular: 'Motivo de Perda/Uso Consumo',
            },
        },
    }
}

export default ptBR;