import { UserPermissions } from "../../../core/services/User/types";

export const dependencies: UserPermissions = [
    { resource: 'emitirnfse', permissions: { list: true, insert: true, update: true, delete: true, } },
    { resource: 'clonarmovestoque', permissions: { list: true, insert: true, update: true, delete: true, } },
    { resource: 'servicospesquisafiscal', permissions: { list: true, } },
    { resource: 'pessoas', permissions: { list: true, } },
    { resource: 'pessoaendereco', permissions: { list: true, } },
];

export const options = ['PermitirImprimir', ];