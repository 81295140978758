import { Helmet } from 'react-helmet';
import { Box, Button, Card, CardActions, CardContent, Hidden, Typography } from '@material-ui/core';
import Config from '../../../config';
import { useState } from 'react';
import { useApi } from '../../services/Api';
import { useLang } from '../../services/Lang';
import Row from '../../components/ui/Row';
import Col from '../../components/ui/Col';
import { ReactComponent as Construction } from '../../../icons/construction.svg';
import { useTheme } from '../../services/Theme';

const ServerOffline = () => {
    const [lastPath] = useState(() => localStorage.getItem('lastPath') || '/');
    const api = useApi();
    const { theme } = useTheme()

    const doReconnect = () => {
        localStorage.setItem('lastPath', '');
        window.location.pathname = lastPath;
    }

    return (
        <>
            <Helmet>
                <title>{api.isStandAlone ? '' : (' | ' + Config.name)}</title>
                {/* <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"></meta> */}
            </Helmet>
            <Row style={{ padding: 12, alignItems: 'center', height: '100%' }}>
                <Col sm={12} md={6} style={{ textAlign: 'center' }}>
                    <Typography style={{ margin: 24, fontSize: '3rem', lineHeight: 1 }}>Servidor Offline!</Typography>
                    <Typography style={{ margin: 24, fontSize: '1.5rem', color: theme.theme.palette.text.secondary }}>Aguarde alguns instantes e tente novamente.</Typography>
                    <Typography style={{ margin: 24, fontSize: '1.5rem', color: theme.theme.palette.text.secondary }}>Já estamos trabalhando para restaurar o serviço. Sentimos muito pelo incoveniente.</Typography>
                    <Button variant='contained' color='primary' style={{ margin: 24 }} onClick={doReconnect}>Tentar Novamente</Button>
                </Col>
                <Col sm={12} md={6} style={{ padding: 0, display: 'flex', justifyContent: 'center' }}>
                    <Construction style={{ maxWidth: 360, maxHeight: 360 }} />
                </Col>
            </Row >
        </>
    )
};

export default ServerOffline;
