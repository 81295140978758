import ptBR from './pt-br';
import enUS from './en-us';
import { Lang } from '../core/services/Lang/types';

export const LangList = [ptBR, enUS];
export const PhoneCountryOrder = ["55", "1", "*"];

export const CountryLang: { [key: string | number]: Lang } = {
    1490: enUS,
    2496: enUS,
    1058: ptBR
}