export const dependencies = [
    { resource: 'gruposprodutos', permissions: { list: true } },
    { resource: 'categoriasprodutos', permissions: { list: true } },
    { resource: 'produtosimagens', permissions: { list: true, record: true, insert: true, update: true } },
    { resource: 'produtosestoques', permissions: { list: true } },
    { resource: 'qboaccounts', permissions: { list: true, } },
    { resource: 'produtoscatalogo', permissions: { list: true } },
    { resource: 'movestoqueprodutos', permissions: { list: true } },
    { resource: 'movimentosestoqueitens', permissions: { list: true } },
];

export const options = ['EsconderPrecoCusto', 'BloquearPrecoVenda', 'PermitirImprimir', 'PermitirImpCurvaABC', 'PermitirImpTabela'];