import { lazy } from 'react';
import { Module } from '../core/types';
import { MonetizationOn, AccountBalance, Person, CreditScore } from '@mui/icons-material';

// ############### props from routes

import { options as TransactionsOptions, dependencies as TransactionsDep } from '../pages/Financeiro/Lancamentos/route.props';
import { options as CustomersOptions, dependencies as CustomersDep } from '../pages/Comercial/Clientes/route.props';
import { dependencies as PaymentsDep } from '../pages/Financeiro/MetodosPagamento/route.props';

// ############### Contents of Routes

const Lancamentos = lazy(() => import('../pages/Financeiro/Lancamentos'));
const CartoesCredito = lazy(() => import('../pages/Financeiro/CartoesCredito'));
const Customers = lazy(() => import('../pages/Comercial/Clientes'));
const MetodosPagamento = lazy(() => import('../pages/Financeiro/MetodosPagamento'));
const Contas = lazy(() => import('../pages/Financeiro/Contas'));
const Categorias = lazy(() => import('../pages/Financeiro/Categorias'));
const Convenios = lazy(() => import('../pages/Financeiro/Convenios'));
const Cobranca = lazy(() => import('../pages/Financeiro/Cobranca'));

// ############### Routes

export const BR_Financeiro: Module = {
    name: 'financeiro',
    routes: [
        {
            name: 'financeiro', icon: <AccountBalance />,
            children: [
                { name: 'lancamentos', element: <Lancamentos />, icon: <MonetizationOn />, resource: 'lancamentoscaixa', options: TransactionsOptions, dependencies: TransactionsDep },
                { name: 'cartoes', element: <CartoesCredito />, icon: <CreditScore />, resource: 'lancamentoscaixa', options: TransactionsOptions, dependencies: TransactionsDep },
                { name: 'pessoas', element: <Customers path="pessoas" group="financeiro" />, icon: <Person />, resource: 'pessoas', dependencies: CustomersDep, options: CustomersOptions, ifDoesntHaveModules: ['estoque', 'contratos'] },
                { name: 'contas', element: <Contas />, resource: 'contas' },
                { name: 'metodospagamento', element: <MetodosPagamento />, resource: 'metodospagamento', dependencies: PaymentsDep, },
                { name: 'categorias', element: <Categorias />, resource: 'categoriasfinanceiras' },
                { name: 'convenios', element: <Convenios />, resource: 'convenios', ifHaveModules: ['boleto'] },
                { name: 'cobrancas', element: <Cobranca />, resource: 'cobrancas', ifHaveModules: ['cobranca'] },
            ]
        },
    ],
}